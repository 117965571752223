import React, { useState } from "react";
import List from "@material-ui/core/List";
import { useHistory } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import LinkIcon from "@material-ui/icons/Link";
import PersonIcon from "@material-ui/icons/Person";
import MessageIcon from "@material-ui/icons/Message";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import copy from "copy-to-clipboard";
import SuccessSnackBar from "../common/SuccessSnackBar";
import SidebarRow from "./SidebarRow";

type Props = {
  page: string;
  depthStep?: number;
  depth?: number;
  expanded?: boolean;
  settingBarHandler?: () => void;
  tableContentHandler?: () => void;
  commentSiderBarHandler?: () => void;
};

export default function Sidebar({
  page,
  depthStep,
  depth,
  expanded,
  settingBarHandler,
  commentSiderBarHandler,
  tableContentHandler,
}: Props) {
  const history = useHistory();
  const [copiedLink, setCopiedLink] = useState(false);

  const dashboardData = [
    {
      name: "home",
      label: "My Guides",
      Icon: MenuBookIcon,
      onClick: () => history.push(`/dashboard`),
    },
    {
      name: "collections",
      label: "Collections",
      Icon: AppsIcon,
      onClick: () => history.push(`/collections`),
    },
    {
      name: "community",
      label: "Community",
      Icon: PersonIcon,
      onClick: () => history.push(`/communities`),
    },
  ];

  const notebookData = [
    { name: "home", label: "Back", Icon: HomeIcon, onClick: () => null },
    {
      name: "notebook",
      label: "Notebook",
      Icon: MenuBookIcon,
      onClick: () => {
        if (tableContentHandler) tableContentHandler();
      },
    },
    {
      name: "comments",
      label: "Comments",
      Icon: MessageIcon,
      onClick: () => {
        if (commentSiderBarHandler) commentSiderBarHandler();
      },
    },
    {
      name: "settings",
      label: "Settings",
      Icon: SettingsIcon,
      onClick: () => {
        if (settingBarHandler) settingBarHandler();
      },
    },
    {
      name: "share-link",
      label: "Share Link",
      Icon: LinkIcon,
      onClick: () => {
        setCopiedLink(true);
        copy(`www.bookish.fyi${history.location.pathname}`);
      },
    },
  ];

  let data: any[];
  if (page === "dashboard") {
    data = dashboardData;
  } else if (page === "notebook") {
    data = notebookData;
  } else {
    data = [];
  }

  return (
    <>
      <List disablePadding dense>
        {data.map((item, index) => (
          <React.Fragment key={`${item.name}${index}`}>
            <SidebarRow
              depthStep={depthStep}
              depth={depth}
              expanded={expanded}
              item={item}
            />
          </React.Fragment>
        ))}
      </List>
      <SuccessSnackBar
        message="Link copied to clipboard!"
        open={copiedLink}
        setOpen={setCopiedLink}
      />
    </>
  );
}
