import React, { SVGProps } from "react";

interface EmptySectionPlaceholderProps {
  Icon: React.FC<SVGProps<SVGSVGElement>>;
  text: string;
}

export default function EmptySectionPlaceholder(
  props: EmptySectionPlaceholderProps
) {
  const { Icon, text } = props;
  return (
    <div className="w-full py-5 flex flex-col justify-center items-center rounded-md bg-gray-100 gap-2">
      <div className="rounded-sm p-1 bg-white">
        <Icon className="w-5 h-5 text-gray-500" />
      </div>

      <div className="text-gray-500">{text}</div>
    </div>
  );
}
