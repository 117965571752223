import React from 'react';

import Typography from '@material-ui/core/Typography';

import { OutlinedActionButton } from '../../common/OutlinedActionButton';

export default function GoogleDocContentComponent(props: any) {
  const { googleDoc, id } = props;
  const urlStart = "https://docs.google.com/document/d/";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        <Typography variant="h4">{googleDoc && googleDoc.title}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {googleDoc.description}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
          padding: 20,
        }}
      >
        <OutlinedActionButton
          buttonText="View in Google Doc"
          onClick={() => window.open(urlStart + id)}
        />
      </div>
    </>
  );
}
