import React from "react";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

export default function GoogleDocContentComponent(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      {htmlToReactParser.parse(
        props.html_doc_content
          .replace("max-width:468pt", "max-width:850pt")
          .replace(/background-color:#ffffff/g, "background-color:default")
          .replace(/color:#000000/g, "color:primary")
      )}
    </div>
  );
}
