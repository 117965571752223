import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function GoogleSheetContent(props) {
  const sheetHeadings = props.content[0];
  const tableFormattedData = props.content.slice(1).map((row) => {
    const formattedRow = {};
    sheetHeadings.map((heading, i) => {
      formattedRow[heading] = row[i];
      return null;
    });
    return formattedRow;
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: 40,
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {sheetHeadings.map((heading) => <TableCell>{heading}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableFormattedData.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {sheetHeadings.map((heading) => <TableCell>{row[heading]}</TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
