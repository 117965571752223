import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import saveIcon from "../../../assets/images/saveicon.svg";
import { GetCollectionsByUser } from "../../../db/collection";
import { useUserObject } from "../../../util/auth.js";
import CollectionModalNewCollectionFooterBar from "./CollectionModalNewCollectionFooterBar";
import CollectionRowCard from "./CollectionRowCard";
import "./modal.css";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CollectionsModal(props) {
  const { notebookID, notebookTitle, openCollectionModal, handleClose } = props;
  const userObject = useUserObject();
  let viewerUserID;
  if (userObject !== undefined && userObject.status === "success") {
    viewerUserID = userObject.data.author.id;
  }
  const { data, status } = GetCollectionsByUser(viewerUserID);
  const [createNewCollectionMode, setCreateNewCollectionMode] =
    React.useState(false);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openCollectionModal}
    >
      <div className="title-wrapper">
        <DialogTitle onClose={handleClose} className="title">
          <img src={saveIcon} alt="" className="saveIcon" />
          Save [{notebookTitle}] to Collection
        </DialogTitle>
      </div>
      <DialogContent>
        {status === "success" &&
          data.collections.map((row, i) => (
            <CollectionRowCard
              title={row.title}
              public={row.make_public}
              userID={viewerUserID}
              collectionID={row.id}
              googleDocID={notebookID}
            />
          ))}
      </DialogContent>
      <CollectionModalNewCollectionFooterBar
        viewerUserID={viewerUserID}
        createNewCollectionMode={createNewCollectionMode}
        setCreateNewCollectionMode={setCreateNewCollectionMode}
      />
    </Dialog>
  );
}
