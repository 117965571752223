import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth.js";
import { GoogleButton, AvatarImage, Wrapper, Content } from "./Global";

const useStyles = makeStyles((theme) => ({
  lastUsedIndicator: {
    position: "absolute",
    top: "-6px",
    right: "-6px",
    fontSize: "13px",
    lineHeight: 1,
    textTransform: "initial",
    padding: "3px 5px",
    borderRadius: "7px",
    color: "white",
    backgroundColor: theme.palette.warning.main,
    opacity: 0.8,
  },
}));

export default function AuthSocial(props) {
  const auth = useAuth();
  const classes = useStyles();
  const [pending, setPending] = useState(null);
  const [lastUsed, setLastUsed] = useState(null);

  const providerDisplayNames = {
    google: "Google",
    facebook: "Facebook",
    twitter: "Twitter",
    github: "GitHub",
  };

  const onSigninWithProvider = (provider) => {
    setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user) => {
        localStorage.setItem("lastUsedAuthProvider", provider);
        props.onAuth(user);
      })
      .catch((error) => {
        setPending(null);
        props.onError(error.message);
      });
  };

  // Get value of last used auth provider
  useEffect(() => {
    if (props.showLastUsed) {
      if (window.localStorage.getItem("lastUsedAuthProvider")) {
        setLastUsed(lastUsed);
      }
    }
  }, [props.showLastUsed]);

  return (
    <Wrapper>
      <Content>
        {props.providers.map((provider) => (
          <GoogleButton
            disabled={pending === provider}
            onClick={() => {
              onSigninWithProvider(provider);
            }}
            key={provider}
          >
            <AvatarImage
              src={`https://uploads.divjoy.com/icon-${provider}.svg`}
              alt={providerDisplayNames[provider]}
              width="16px"
              height="16px"
              margin="10px"
            />

            {pending !== provider && (
              <span>
                {props.buttonText} with {providerDisplayNames[provider]}
              </span>
            )}

            {pending === provider && <CircularProgress size={28} />}

            {provider === lastUsed && (
              <div className={classes.lastUsedIndicator}>Last used</div>
            )}
          </GoogleButton>
        ))}
      </Content>
    </Wrapper>
  );
}
