import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { GetAllGoogleDocsInCollection } from "../db/collection";
import GoogleDocPreviewCard from "../components/google_doc/components/GoogleDocPreviewCard";

const CollectionPage = (props) => {
  const { id } = props.match.params;
  const result = GetAllGoogleDocsInCollection(id);

  return (
    <div style={{ marginTop: 30 }}>
      {result.status === "success" ? (
        <>
          <Typography
            variant="h4"
            gutterBottom={false}
            style={{ paddingTop: 20, marginLeft: 100 }}
          >
            {result.data.collectionName}
          </Typography>
          {result.results > 0 ? (
            <Typography
              variant="h4"
              gutterBottom={false}
              style={{ paddingTop: 20, marginLeft: 100 }}
            >
              No Data
            </Typography>
          ) : (
            <Grid container spacing={3} justify="center">
              {result.data.google_docs.map((googleDoc, index) => (
                <GoogleDocPreviewCard key={index} notebook={googleDoc} />
              ))}
            </Grid>
          )}
        </>
      ) : null}
    </div>
  );
};

export default CollectionPage;
