import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

type Props = {
  title: string;
  authorName: string;
};

const useStyles = makeStyles((theme) => ({
  coverContainer: {
    backgroundColor: "black",
    width: 135,
    height: 170,
    borderRadius: "0px 4px 4px 0px",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardContents: {
    maxWidth: 110,
    overflow: "hidden",
  },
  title: {
    display: "flex",
    flex: 1,
    color: "#FFFFFF",
    fontWeight: "bold",
    paddingTop: 4,
    paddingRight: 4,
    paddingLeft: 4,
  },
  nameText: {
    display: "flex",
    flex: 1,
    maxWidth: 120,
    color: "#FFFFFF",
    paddingRight: 4,
    paddingLeft: 4,
    paddingBottom: 4,
  },
}));

export default function Cover({ title, authorName }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.coverContainer}>
      <div className={classes.cardContents}>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        <Typography
          className={classes.nameText}
          variant="subtitle1"
          color="primary"
        >
          {authorName}
        </Typography>
      </div>
    </div>
  );
}
