import React from "react";
import Grid from "@material-ui/core/Grid";
import { OutlinedActionButton } from "../common/OutlinedActionButton";
import { SolidActionButton } from "../common/SolidActionButton";
import { RECOMMENDED_TAGS } from "./TagsConstants";

export default function TagsSection(props) {
  const { selectedTag, setSelectedTag } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Grid container justify="center">
        {RECOMMENDED_TAGS.map((tag) => (
          <div
            key={tag}
            style={{
              marginLeft: 3,
            }}
          >
            {selectedTag === tag ? (
              <SolidActionButton
                buttonText={tag}
                onClick={() => setSelectedTag("")}
              />
            ) : (
              <OutlinedActionButton
                buttonText={tag}
                onClick={() => setSelectedTag(tag)}
              />
            )}
          </div>
        ))}
      </Grid>
    </div>
  );
}
