import React from "react";
import { CardActionArea, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import lock from "../../../assets/images/lock.svg";
import publicPic from "../../../assets/images/public.svg";
import { SaveGoogleDocToCollection } from "../../../db/collection";
import SuccessSnackBar from "../../common/SuccessSnackBar";
import Cover from "../../cover/Cover";

export default function CollectionRowCard(props) {
  const { title, userID, collectionID, googleDocID } = props;
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);

  // TODO: right now by default, all collections are public
  const publicCollection = true;
  function handleSaveCollection() {
    SaveGoogleDocToCollection({
      add_author: userID,
      collection: collectionID,
      google_doc: googleDocID,
    })
      .then((data) => {
        setOpenSuccessMessage(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Card variant="outlined" className="card">
        <CardActionArea
          className="cardActionArea"
          onClick={() => handleSaveCollection()}
        >
          <div className="diction">
            <div
              className="coverCard"
              style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Cover title={title} authorName="" />
            </div>
            <div className="details">
              <CardContent className="card-content">
                <div className="public-privte-wrap">
                  <Typography component="h5" variant="h5">
                    {title}
                  </Typography>
                  {publicCollection ? (
                    <img src={publicPic} alt="" className="public" />
                  ) : (
                    <img src={lock} alt="" className="lock" />
                  )}
                </div>
                <Typography variant="subtitle2" color="textSecondary">
                  {title}
                </Typography>
              </CardContent>
            </div>
          </div>
        </CardActionArea>
      </Card>
      <SuccessSnackBar
        message={`Successfully saved notebook to ${  title  } collection!`}
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
      />
    </>
  );
}
