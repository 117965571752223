import React from "react";
import AuthSection from "../components/auth/AuthSection";
import { useRouter } from "../util/router.js";

const AuthPage = (props) => {
  const router = useRouter();

  return (
    <AuthSection
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      type={router.query.type}
      providers={["google", "github", "facebook"]}
      afterAuthPath={router.query.next || "/"}
    />
  );
}

export default AuthPage;
