import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useRouter } from "../../util/router.js";
import "./Auth.css";
import AuthFooter from "./AuthFooter";
import AuthSocial from "./AuthSocial";

const Auth = (props) => {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = (user) => {
    router.push(props.afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      {["signup", "signin"].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <AuthSocial
                type={props.type}
                buttonText={props.typeValues.buttonText}
                providers={props.providers}
                showLastUsed
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: "error",
                    message,
                  });
                }}
              />
          )}

          {/* <h2>
            <span>or</span>
          </h2>
          <AuthForm
            type={props.type}
            typeValues={props.typeValues}
            onAuth={handleAuth}
            onFormAlert={handleFormAlert}
          /> */}

          <AuthFooter type={props.type} typeValues={props.typeValues} />
        </>
      )}
    </>
  );
}

export default Auth;
