import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

type ButtonSize = "small" | "medium" | "large";

type Props = {
  buttonText: string;
  isLoading?: boolean;
  onClick: () => void;
  size?: ButtonSize;
};

/**
 * Outlined buttons that are used for functionality defined by onClick handlers
 */
export const OutlinedActionButton = ({
  buttonText,
  isLoading,
  onClick,
  size,
}: Props) => (
  <Button
    variant="outlined"
    size={size ?? "medium"}
    style={{ margin: 2 }}
    disabled={isLoading ?? false}
    onClick={onClick}
  >
    {!isLoading ? buttonText : <CircularProgress size={20} />}
  </Button>
);

export default OutlinedActionButton;
