import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

type Props = {
  duration?: number;
  message: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const SuccessSnackBar = (props: Props) => {
  const { duration, message, open, setOpen } = props;

  const handleCloseAlert = (_event: React.SyntheticEvent, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      {open ? (
        <Snackbar
          open={open}
          autoHideDuration={duration ?? 1300}
          onClose={handleCloseAlert}
        >
          <MuiAlert severity="success" elevation={6} variant="filled">
            {message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default SuccessSnackBar;
