import React from "react";
import { CardActionArea } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { GetUser } from "../../db/user";
import Cover from "../cover/Cover";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 170,
    height: 260,
    borderRadius: 4,
  },
  content: {
    flex: "1",
  },
  cover: {
    margin: 24,
    width: 120,
  },
}));

export default function CollectionCard(props) {
  const classes = useStyles();
  const { collection } = props;
  const history = useHistory();
  const user = GetUser(collection.author);

  return (
    <Grid item>
      <Card className={classes.root} variant="outlined">
        <CardActionArea
          onClick={() => {
            history.push(`/collection/${collection.id}`);
          }}
        >
          <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
            {user && user.data && (
              <Cover title={collection.title} authorName="" />
            )}
          </div>
          <CardContent className={classes.content}>
            <Typography noWrap variant="h5">
              {collection.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
