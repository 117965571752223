import React from "react";

type Props = {
  location: Location,
}

const NotFoundPage = ({ location }: Props) => (
    <div
      style={{
        padding: "50px",
        width: "100%",
        textAlign: "center",
      }}
    >
      The page <code>{location.pathname}</code> could not be found
    </div>
  )

export default NotFoundPage;
