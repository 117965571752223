import { useQuery, queryCache, QueryResult } from "react-query";
import { apiRequest } from "../util/util";

type GoogleDoc = {
  fileID: string;
  author: string;
  title: string;
  description?: string;
  html_doc_content: string;
  tags: string[];
  url: string;
};

export function CreateGoogleDoc(data: GoogleDoc): Promise<GoogleDoc> {
  return apiRequest("google-doc-create", "POST", data).then((doc) => {
    queryCache.invalidateQueries(["google_doc", { fileID: doc.fileID }]);
    return doc;
  });
}

export function GetGoogleDocByFileID(id: string): QueryResult<GoogleDoc> {
  const cacheKey = ["google_doc", { id }];
  const query = () => apiRequest<GoogleDoc>(`google-doc-import/${id}`);
  return useQuery(cacheKey, query, { enabled: !!id });
}

export function GetGoogleDocFromDB(fileID: string): QueryResult<GoogleDoc[]> {
  const cacheKey = ["google_doc", { fileID }];
  const query = () =>
    apiRequest<GoogleDoc[]>(`google-doc-get-from-db/${fileID}`);
  return useQuery(cacheKey, query, { enabled: !!fileID });
}

export function GetGoogleDocByAuthor(
  authorID: string
): QueryResult<{ google_docs: GoogleDoc[]}> {
  const cacheKey = ["google_docs_author", { authorID }];
  const query = () =>
    apiRequest<{ google_docs: GoogleDoc[] }>(`google-docs-get-by-author/${authorID}`);
  return useQuery(cacheKey, query, { enabled: !!authorID });
}

export function GetTopNewsfeedGoogleDocs(): QueryResult<GoogleDoc[]> {
  const cacheKey = ["newsfeed-google-docs"];
  const query = () => apiRequest<GoogleDoc[]>("google-docs-top-get");
  return useQuery(cacheKey, query);
}

export function GetTagGoogleDocs(tag: string): QueryResult<GoogleDoc[]> {
  const cacheKey = ["google-docs-tag-get", { tag }];
  const query = () => apiRequest<GoogleDoc[]>(`google-docs-tag-get/${tag}`);
  return useQuery(cacheKey, query, { enabled: !!tag });
}

export function GetRecentNewsfeedGoogleDocs(): QueryResult<GoogleDoc[]> {
  const cacheKey = ["newsfeed-recent-google-docs"];
  const query = () => apiRequest<GoogleDoc[]>("google-docs-recent-get");
  return useQuery(cacheKey, query);
}
