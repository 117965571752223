import React from "react";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { CreateCollection } from "../../../db/collection";
import { OutlinedActionButton } from "../../common/OutlinedActionButton";
import { SolidActionButton } from "../../common/SolidActionButton";
import SuccessSnackBar from "../../common/SuccessSnackBar";
import "./modal.css";

export default function CollectionModalNewCollectionFooterBar(props) {
  const { viewerUserID, createNewCollectionMode, setCreateNewCollectionMode } =
    props;
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
  const [textInputCollectionTitle, setTextInputCollectionTitle] =
    React.useState("");

  function handleCreateNewCollection() {
    CreateCollection({
      author: viewerUserID,
      title: textInputCollectionTitle,
      make_public: true,
    })
      .then((data) => {
        setOpenSuccessMessage(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div classes="action-Wrapper">
        {!createNewCollectionMode ? (
          <Button
            autoFocus
            onClick={() => setCreateNewCollectionMode(true)}
            className="modal-new-collect"
          >
            <CreateNewFolderIcon className="new-collection-icon" />
            New Collection
          </Button>
        ) : (
          <div>
            <FormGroup noValidate autoComplete="off" className="form">
              <div className="form-new-collection-input">
                <TextField
                  placeholder="New collection name.."
                  variant="outlined"
                  className="input-field"
                  size="small"
                  onChange={(x) => setTextInputCollectionTitle(x.target.value)}
                  value={textInputCollectionTitle}
                />
              </div>
              <div className="form-wrap">
                {/* <FormControlLabel
                      control={
                        <Switch
                          checked={!publicToggle}
                          onChange={() => setPublicToggle(!publicToggle)}
                          name="publicToggle"
                          color=""
                        />
                      }
                      label="Private Collection"
                      labelPlacement="start"
                    /> */}
              </div>
            </FormGroup>
            <div className="btn-wrap">
              <OutlinedActionButton
                buttonText="Cancel"
                onClick={() => setCreateNewCollectionMode(false)}
              />
              <div style={{ marginLeft: 5 }}>
                <SolidActionButton
                  buttonText="Create"
                  onClick={() => handleCreateNewCollection()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <SuccessSnackBar
        message="Successfully created new collection! Please refresh."
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
      />
    </>
  );
}
