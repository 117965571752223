import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import MenuIcon from "@material-ui/icons/Menu";
import useDarkMode from "use-dark-mode";
import { SolidNavButton } from "../common/SolidNavButton";
import { OutlinedActionButton } from "../common/OutlinedActionButton";
import CreateAccountModal from "../auth/auth-modal/CreateAccountModal";
import { useAuth, useUserObject } from "../../util/auth.js";
import { Link } from "../../util/router.js";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const [openCreateAccountModal, setOpenCreateAccountModal] =
    React.useState(false);

  const auth = useAuth();
  const userObject = useUserObject();

  // Use inverted logo if specified
  // and we are in dark mode
  // const logo =
  //   props.logoInverted && darkMode.value ? props.logoInverted : props.logo;
  const { logo } = props;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  const newNotebookButton = () =>
    auth.user ? (
      <SolidNavButton buttonText="New Guide" path="/new" />
    ) : (
      <OutlinedActionButton
        buttonText="New Guide"
        onClick={(e) => {
          e.stopPropagation();
          setOpenCreateAccountModal(true);
        }}
      />
    );

  const toggleLightDarkMode = () => (
    <IconButton
      color="inherit"
      onClick={darkMode.toggle}
      style={{ opacity: 0.6 }}
    >
      {darkMode.value && <NightsStayIcon />}

      {!darkMode.value && <WbSunnyIcon />}
    </IconButton>
  );

  const loggedOutNavBarButtons = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={{ marginRight: 10 }}>{newNotebookButton()}</div>
      <SolidNavButton
        buttonText="Sign in"
        path="/auth/signin"
        overrideColor="default"
        textColor="primary"
      />
      {/* <SolidNavButton
            buttonText="Sign up"
            path="/auth/signup"
            overrideColor="default"
            textColor="primary"
          /> */}
      {/* <div style={{ marginRight: 20 }}>{toggleLightDarkMode()}</div> */}
    </div>
  );

  const loggedInNavBarButtons = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={{ marginRight: 10 }}>{newNotebookButton()}</div>
      <Button
        color="inherit"
        aria-label="Account"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={(event) => {
          handleOpenMenu(event, "account-menu");
        }}
      >
        Account
        <ExpandMoreIcon className={classes.buttonIcon} />
      </Button>
      <Menu
        id="account-menu"
        open={menuState && menuState.id === "account-menu"}
        anchorEl={menuState && menuState.anchor}
        getContentAnchorEl={undefined}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {userObject !== undefined && userObject.status === "success" && (
          <MenuItem
            component={Link}
            to={`/profile/${userObject.data.author.id}`}
          >
            Profile
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={(event) => {
            auth.signout();
          }}
        >
          Signout
        </MenuItem>
      </Menu>
      {/* <div style={{ marginLeft: 8 }}>{toggleLightDarkMode()}</div> */}
    </div>
  );

  const loggedOutCollapsedSidebar = () => (
    <>
      <ListItem>{newNotebookButton()}</ListItem>
      <Divider />
      <ListItem>
        <SolidNavButton
          buttonText="Sign in"
          path="/auth/signin"
          overrideColor="default"
          textColor="primary"
        />
      </ListItem>
      <ListItem>
        <SolidNavButton
          buttonText="Sign up"
          path="/auth/signup"
          overrideColor="default"
          textColor="primary"
        />
      </ListItem>
      {/* <div style={{ marginLeft: 8 }}>{toggleLightDarkMode()}</div> */}
    </>
  );

  const loggedInCollapsedSidebar = () => (
    <>
      <ListItem>{newNotebookButton()}</ListItem>
      <Divider />
      {userObject !== undefined && userObject.status === "success" && (
        <ListItem>
          <SolidNavButton
            buttonText="Profile"
            path={`/profile/${userObject.data.author.id}`}
            overrideColor="default"
            textColor="primary"
          />
        </ListItem>
      )}
      <ListItem>
        <SolidNavButton
          size="small"
          buttonText="Sign out"
          path="/auth/signup"
          overrideColor="default"
          textColor="primary"
          onClick={() => {
            auth.signout();
          }}
        />
      </ListItem>
      {/* <div style={{ marginLeft: 8 }}>{toggleLightDarkMode()}</div> */}
    </>
  );

  return (
    <Section
      bgColor={props.color}
      size="auto"
      style={{ borderBottom: " 1px solid #e0e0e0" }}
    >
      <CreateAccountModal
        openCreateAccountModal={openCreateAccountModal}
        handleClose={() => {
          setOpenCreateAccountModal(false);
        }}
        providers={["google", "github", "facebook"]}
      />
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters>
          <Toolbar>
            <Link to="/">{logo}</Link>
            <h1
              style={{
                textAlign: "left",
                fontSize: 28,
                fontWeight: "600",
              }}
            >
              Bookish
            </h1>
            <div className={classes.spacer} />
            <Hidden mdUp implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown implementation="css">
              {!auth.user && <>{loggedOutNavBarButtons()}</>}

              {auth.user && <>{loggedInNavBarButtons()}</>}
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {!auth.user && <>{loggedOutCollapsedSidebar()}</>}

          {auth.user && <>{loggedInCollapsedSidebar()}</>}
        </List>
      </Drawer>
    </Section>
  );
}
