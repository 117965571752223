import HomeTopSection from "../components/google_doc/HomeTopSection";
import FeatureSection from "../components/landing/FeatureSection";
import HeroSection from "../components/landing/HeroSection";
import { useAuth } from "../util/auth";

const IndexPage = () => {
  const auth = useAuth();

  return (
    <>
      {!auth.user && (
        <div>
          <HeroSection />
          <FeatureSection />
        </div>
      )}

      <div style={{ padding: "100px" }}>
        <HomeTopSection />
      </div>
    </>
  );
};

export default IndexPage;
