import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import ReactFrappeChart from "react-frappe-charts";
import { useParams } from "react-router-dom";
import { GetUserContributions } from "../../db/user";

export default function Contributions() {
  const { id } = useParams();

  const {
    data: contributionDates,
    status: contributionStatus,
    error: contributionError,
  } = GetUserContributions(id);

  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  startDate.setMonth(startDate.getMonth() + 1);
  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + 2);

  const timeData = {};

  // TODO: contributions should be writing+edit days, not only post creation dates
  // TODO: write anything = 1, write 500 words = darker shade, write 1000 words, etc.
  // eslint-disable-next-line no-unused-expressions
  contributionStatus === "success" &&
    typeof contributionDates !== "undefined" &&
    contributionDates.contributions.rows.length > 0 &&
    contributionDates.contributions.rows.map((creationDate) => {
      timeData[
        Math.floor(new Date(creationDate.creationDate).valueOf() / 1000)
      ] = 1;
      return null;
    });

  return (
    <>
      {contributionError && (
        <Box mb={3}>
          <Alert severity="error">{contributionError.message}</Alert>
        </Box>
      )}

      <Typography variant="h5" gutterBottom={false}>
        Contributions
      </Typography>
      <Typography variant="subtitle2" gutterBottom={false}>
        Dates you've created a new guide
      </Typography>
      <ReactFrappeChart
        type="heatmap"
        // discreteDomains={0}
        colors={["#ebedf0", "#c0ddf9", "#73b3f3", "#3886e1", "#17459e"]}
        radius={1}
        height={240}
        data={{
          dataPoints: timeData,
          start: startDate,
          end: endDate,
        }}
      />
    </>
  );
}
