import React from "react";
import { CardActionArea } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { GetUser } from "../../../db/user";
import CreateAccountModal from "../../auth/auth-modal/CreateAccountModal";
import CollectionsModal from "../../collection/collection-modal/CollectionsModal";
import Cover from "../../cover/Cover";
import { useUserObject } from "../../../util/auth.js";

const useStyles = makeStyles((theme) => ({
  desktop: {
    display: "flex",
    width: 500,
    height: 200,
    borderRadius: 5,
    "&:hover": {
      background: "#efefef",
      cursor: "pointer",
    },
  },
  mobile: {
    display: "flex",
    width: 300,
    height: 200,
    borderRadius: 5,
    "&:hover": {
      background: "#efefef",
      cursor: "pointer",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1",
  },
  cover: {
    margin: 24,
    width: 120,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  cardActionArea: {
    display: "flex",
    maxWidth: 170,
  },
}));

export default function GoogleDocPreviewCard(props) {
  const { notebook } = props;
  const classes = useStyles();
  const theme = useTheme();
  const userObject = useUserObject();
  const history = useHistory();
  const user = GetUser(notebook.author);
  let viewerUserID;
  if (userObject !== undefined && userObject.status === "success") {
    viewerUserID = userObject.data.author.id;
  }

  // Smaller than laptop width, use smaller cards
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [openCreateAccountModal, setOpenCreateAccountModal] =
    React.useState(false);
  const [openCollectionModal, setOpenCollectionModal] = React.useState(false);

  const cardCoverArea = (nb) => (
    <CardActionArea className={classes.cardActionArea}>
      <div style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
        {user && user.data && <Cover title={nb.title} authorName="" />}
      </div>
    </CardActionArea>
  );

  const cardContentArea = (nb) => (
    <div className={classes.details}>
      <CardContent className={classes.content}>
        <Typography variant="h5">{nb.title}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {nb.description}
        </Typography>

        <div className={classes.controls}>
          <IconButton
            aria-label="bookmark"
            onClick={(e) => {
              e.stopPropagation();
              // eslint-disable-next-line no-unused-expressions
              viewerUserID
                ? setOpenCollectionModal(true)
                : setOpenCreateAccountModal(true);
            }}
          >
            {theme.direction === "rtl" ? (
              <BookmarkIcon />
            ) : (
              <BookmarkBorderIcon />
            )}
          </IconButton>
          <IconButton
            aria-label="people"
            onClick={(e) => {
              history.push(`/profile/${nb.author}`);
              e.stopPropagation();
            }}
          >
            <PersonOutlineIcon />
          </IconButton>
        </div>
      </CardContent>
    </div>
  );

  const createAccountModal = () => (
    <CreateAccountModal
      openCreateAccountModal={openCreateAccountModal}
      handleClose={() => {
        setOpenCreateAccountModal(false);
      }}
      providers={["google", "github", "facebook"]}
    />
  );

  const saveToCollectionsModal = () => (
    <CollectionsModal
      openCollectionModal={openCollectionModal}
      handleClose={() => {
        setOpenCollectionModal(false);
      }}
      notebookID={notebook.fileid}
      notebookTitle={notebook.title}
    />
  );

  return (
    <>
      <Grid item>
        <Card
          className={isDesktopOrLaptop ? classes.desktop : classes.mobile}
          variant="outlined"
          onClick={() => {
            history.push(`/notebook/${notebook.fileid}`);
          }}
        >
          {cardCoverArea(notebook)}
          {cardContentArea(notebook)}
        </Card>
      </Grid>
      {createAccountModal()}
      {saveToCollectionsModal()}
    </>
  );
}
