export const RECOMMENDED_TAGS = [
  "business",
  "crypto",
  "entertainment",
  "lifestyle",
  "relationships",
  "startups",
  "technology",
  "travel",
];
