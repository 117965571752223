import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Link } from '../../util/router.js';

type ButtonSize = "small" | "medium" | "large";

type Props = {
  buttonText: string;
  isLoading?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  path: string;
};

/**
 * Outlined buttons that are used for navigation
 */
export const OutlinedNavButton = ({
  buttonText,
  path,
  isLoading,
  size,
  onClick,
}: Props) => (
  <Button
    variant="outlined"
    size={size ?? "medium"}
    component={Link}
    onClick={onClick || (() => null)}
    to={path}
    style={{
      margin: 2,
    }}
  >
    {!isLoading ? buttonText : <CircularProgress size={20} />}
  </Button>
);
