import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { useAuth, requireAuth } from "../util/auth.js";
import { useRouter } from "../util/router.js";
import { redirectToCheckout } from "../util/stripe.js";
import PageLoader from "../components/website-template/PageLoader";

const PurchasePage = (props) => {
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState();

  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user already has an active plan
      // then take them to Stripe billing
      router.push("/settings/billing");
    } else {
      // Otherwise go to checkout
      redirectToCheckout(router.query.plan).catch((error) => {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
    }
  }, []);

  return (
    <PageLoader>
      {formAlert && (
        <Alert severity={formAlert.type} style={{ maxWidth: "500px" }}>
          {formAlert.message}
        </Alert>
      )}
    </PageLoader>
  );
};

export default requireAuth(PurchasePage);
