import React from "react";
import Grid from "@material-ui/core/Grid";
import { GetTagGoogleDocs } from "../../db/google_doc";
import GoogleDocPreviewCard from "./components/GoogleDocPreviewCard";

export default function GoogleDocsByTopicSection(props) {
  const result = GetTagGoogleDocs(props.selectedTag);

  return (
    <div>
      {props.selectedTag !== "" && (
        <Grid container spacing={6} justify="center">
          {result.status === "success" &&
            result.data.google_docs.map((googleDoc, index) => (
              <GoogleDocPreviewCard key={index} notebook={googleDoc} />
            ))}
        </Grid>
      )}
    </div>
  );
}
