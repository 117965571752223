import React from "react";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { GetTagGoogleDocs } from "../db/google_doc";
import GoogleDocPreviewCard from "../components/google_doc/components/GoogleDocPreviewCard";

const ExploreTag = () => {
  const { tag } = useParams();
  const result = GetTagGoogleDocs(tag);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 40,
        }}
      >
        <Typography variant="h4" gutterBottom={false}>
          {tag.charAt(0).toUpperCase() + tag.slice(1)}
        </Typography>
      </div>
      {tag !== "" && (
        <Grid container spacing={6} justify="center">
          {result.status === "success" &&
            result.data.google_docs.map((googleDoc, index) => (
              <GoogleDocPreviewCard key={index} notebook={googleDoc} />
            ))}
        </Grid>
      )}
    </>
  );
};

export default ExploreTag;
