import React from "react";
import { Dialog } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import AuthSocial from "../AuthSocial";
import "./modal.css";
import { OutlinedNavButton } from "../../common/OutlinedNavButton";

const DialogContent = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CreateAccountModal(props) {
  const { openCreateAccountModal, handleClose, providers } = props;

  const handleAuth = () => {
    handleClose();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openCreateAccountModal}
      onClose={handleClose}
    >
      <div className="title-wrapper">
        <DialogTitle onClose={handleClose} className="title">
          Create an account
        </DialogTitle>
      </div>
      <DialogContent>
        Sign up to create, bookmark, and access your notebooks anytime
      </DialogContent>
      {props.providers && props.providers.length && (
        <DialogContent>
          <AuthSocial
            type={props.type}
            buttonText="Sign up"
            providers={providers}
            showLastUsed
            onAuth={handleAuth}
            onError={(_) => null}
          />
        </DialogContent>
      )}
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          Have an account already?
          <div style={{ marginLeft: 10 }}>
            <OutlinedNavButton
              onClick={() => {
                handleClose();
              }}
              buttonText="Sign in"
              path="/auth/signin"
              textColor="primary"
              overrideColor="default"
              size="small"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
