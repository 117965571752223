import React from "react";
import { CollectionIcon } from "@heroicons/react/solid";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { GetCollectionsByUser } from "../../db/collection";
import Collection from "../collection/CollectionCard";
import EmptySectionPlaceholder from "./EmptySectionPlaceholder";

export default function ProfileCollectionSection(props) {
  const { id } = useParams();
  const { maxShow } = props;
  const result = GetCollectionsByUser(id);

  return (
    <Grid container spacing={3}>
      {result.status === "loading" && <div>Loading Data...</div>}
      {result.status === "success" && result.data.collections.length > 0 ? (
        result.data.collections.map((collection, index) =>
          index < maxShow ? (
            <Collection key={index} collection={collection} />
          ) : null
        )
      ) : (
        <EmptySectionPlaceholder
          text="No collections added yet"
          Icon={CollectionIcon}
        />
      )}
    </Grid>
  );
}
