import React from "react";
import SettingsSection from "../components/settings/SettingsSection";
import { useRouter } from "../util/router";
import { requireAuth } from "../util/auth";

const SettingsPage = () => {
  const router = useRouter();

  return (
    <SettingsSection
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      section={router.query.section}
      key={router.query.section}
    />
  );
};

export default requireAuth(SettingsPage);
