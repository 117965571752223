import ReactDom from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import "./assets/main.css";
import "./assets/tailwind.css";

Sentry.init({
  dsn: "https://8b4e672662cd48a5a74bc1b37144f5f7@o1425054.ingest.sentry.io/4504126459936768",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDom.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
