import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { GetGoogleDocByFileID, GetGoogleDocFromDB } from '../../db/google_doc';
import GoogleDocContentComponent from './components/GoogleDocContent';
import GoogleDocSettings from './components/GoogleDocSettings';
import GoogleDocViewMode from './components/GoogleDocViewMode';

const useStyles = makeStyles(() => ({
  paperblocks: {
    minHeight: "300px",
  },
}));

export default function GoogleDocPage(props) {
  let { id } = useParams();
  id = props.newImport ? props.googleDocsURL.split("/")[5] : id.toString();
  const [editMode, setEditMode] = useState(props.newImport);
  const [googleDocData, setGoogleDocData] = useState(null)
  const [fetchDataStatus, setFetchDataStatus] = useState(null)
  const [fetchDataError, setFetchDataError] = useState(null)
  const classes = useStyles();

  const { data, status, error } = props.newImport
    ? GetGoogleDocByFileID(id)
    : GetGoogleDocFromDB(id);

  useEffect(() => {
    if (status === 'success' && data && data.google_doc) {
      setGoogleDocData(data)
    }
    setFetchDataStatus(status)
    setFetchDataError(error)

  }, [id, data])


  return (
    <>
      {fetchDataError && (
        <Box mb={3}>
          <Alert severity="error">
            {props.newImport
              ? "Try again with a different FileID. Please make sure the doc is public and that you have given correct access (View, Comment, or Edit)."
              : "Sorry, the file you have requested is not available."}
          </Alert>
        </Box>
      )}

      {fetchDataStatus === "loading" && (
        <Paper className={classes.paperblocks}>
          <Box py={5} px={3} align="center">
            {fetchDataStatus === "loading" && <CircularProgress size={32} />}
          </Box>
        </Paper>
      )}

      {fetchDataStatus === "success" && googleDocData && googleDocData.google_doc && (
        <>
          {editMode ? (
            <GoogleDocSettings
              data={googleDocData}
              url={props.googleDocsURL}
              setEditMode={setEditMode}
            />
          ) : (
            <GoogleDocViewMode googleDoc={googleDocData.google_doc} id={id} />
          )}
          {props.newImport ? (
            <GoogleDocContentComponent html_doc_content={googleDocData.google_doc} />
          ) : (
            <GoogleDocContentComponent
              html_doc_content={googleDocData.google_doc.html_doc_content}
            />
          )}
        </>
      )}
    </>
  );
}
