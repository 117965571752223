import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { GetTopNewsfeedGoogleDocs } from "../../db/google_doc";
import { SolidActionButton } from "../common/SolidActionButton";
import TagsSection from "../tags/TagsSection";
import GoogleDocPreviewCard from "./components/GoogleDocPreviewCard";
import GoogleDocsByTopicSection from "./GoogleDocsByTopicSection";

export default function HomeTopSection() {
  const result = GetTopNewsfeedGoogleDocs();
  const [selectedTag, setSelectedTag] = useState("");
  const history = useHistory();

  return (
    <div>
      <TagsSection selectedTag={selectedTag} setSelectedTag={setSelectedTag} />

      {selectedTag === "" ? (
        <div style={{ marginTop: 85 }}>
          <Grid container spacing={6} justify="center">
            {result.status === "success" &&
              result.data.google_docs.map((googleDoc, index) => (
                <GoogleDocPreviewCard key={index} notebook={googleDoc} />
              ))}
          </Grid>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "20%",
              float: "right",
              marginTop: 10,
            }}
          >
            <SolidActionButton
              buttonText="View More"
              size="small"
              onClick={() => {
                history.push(`/topic/${selectedTag}`);
              }}
            />
          </div>
          <GoogleDocsByTopicSection selectedTag={selectedTag} />
        </>
      )}
    </div>
  );
}
