import { useQuery, queryCache } from "react-query";
import { apiRequest } from "../util/util";

// Collection type
/// //////

export function CreateCollection(data) {
  return apiRequest("collection-create", "POST", data).then((collection) => {
    queryCache.invalidateQueries(["collections", { user: collection.user }]);
    return collection;
  });
}

export function DeleteCollection(id) {
  return apiRequest(`collection-delete/${id}`, "DELETE").then(() => {
    queryCache.invalidateQueries(["collection", { id }]);
    return true;
  });
}

export function GetCollection(id) {
  const cacheKey = ["collection", { id }];
  const query = () => apiRequest(`collection-get/${id}`);
  return useQuery(cacheKey, query, { enabled: !!id });
}

export function GetCollectionsByUser(userID) {
  const cacheKey = ["collections-created-get", { userID }];
  const query = () => apiRequest(`collections-created-get/${userID}`);
  return useQuery(cacheKey, query, { enabled: !!userID });
}

export function GetTopCollections() {
  const cacheKey = ["collections-top-get"];
  const query = () => apiRequest("collections-top-get");
  return useQuery(cacheKey, query);
}

// Get/add/delete user saved collections
/// //////

export function SaveCollection(data) {
  return apiRequest("collection-saved-create", "POST", data).then(
    (collection) => {
      queryCache.invalidateQueries(["collections", { user: collection.user }]);
      return collection;
    }
  );
}

export function DeleteSavedCollection(id) {
  return apiRequest(`collection-saved-delete/${id}`, "DELETE").then(() => {
    queryCache.invalidateQueries(["collection", { id }]);
    return true;
  });
}

export function GetSavedCollectionsByUser(userID) {
  const cacheKey = ["collection", { userID }];
  const query = () => apiRequest(`collection-saved-get/${userID}`);
  return useQuery(cacheKey, query, { enabled: !!userID });
}

// GOOGLE DOCS
/// //////

export function SaveGoogleDocToCollection(data) {
  return apiRequest("collection-google-doc-add", "POST", data).then(
    (collection) => {
      queryCache.invalidateQueries(["collections", { user: collection.user }]);
      return collection;
    }
  );
}

export function RemoveGoogleDocFromCollection(id) {
  return apiRequest(`collection-google-doc-delete/${id}`, "DELETE").then(() => {
    queryCache.invalidateQueries(["collection", { id }]);
    return true;
  });
}

export function GetAllGoogleDocsInCollection(id) {
  const cacheKey = ["collection-google-docs-get", { id }];
  const query = () => apiRequest(`collection-google-docs-get/${id}`);
  return useQuery(cacheKey, query, { enabled: !!id });
}

// NOTEBOOKS
/// //////

export function SaveNotebookToCollection(data) {
  return apiRequest("collection-notebook-add", "POST", data).then(
    (collection) => {
      queryCache.invalidateQueries(["collections", { user: collection.user }]);
      return collection;
    }
  );
}

export function RemoveNotebookFromCollection(id) {
  return apiRequest(`collection-notebook-delete/${id}`, "DELETE").then(() => {
    queryCache.invalidateQueries(["collection", { id }]);
    return true;
  });
}

export function GetAllNotebooksInCollection(id) {
  const cacheKey = ["collection-notebooks-get", { id }];
  const query = () => apiRequest(`collection-notebooks-get/${id}`);
  return useQuery(cacheKey, query, { enabled: !!id });
}
