import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { GetCollectionsByUser } from "../../db/collection";
import { useUserObject } from "../../util/auth.js";
import Collection from "./CollectionCard";

export default function ProfileUserCollectionsSections(props) {
  const userObject = useUserObject();
  let collections;
  if (userObject !== undefined && userObject !== null) {
    collections = GetCollectionsByUser(userObject.data.author.id);
  }

  return (
    <div>
      <div>
        <div style={{ paddingTop: 20 }}>
          <Typography variant="h5" gutterBottom={false}>
            My Collections
          </Typography>
        </div>
        <Grid container spacing={10} justify="center">
          {collections.status === "success" &&
            collections.data.collections.map((collection, index) => (
              <Collection key={index} collection={collection} />
            ))}
        </Grid>
      </div>
    </div>
  );
}
