import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { GetUser, GetUserByEmail, CreateUser, updateUser } from "../db/user";
import PageLoader from "../components/website-template/PageLoader";
import auth0 from "./auth0";
import { ResponseError } from "./util";
import { history } from "./router";
import { getFriendlyPlanId } from "./prices";
import analytics from "./analytics";

// Whether to merge extra user data from database into auth.user
const MERGE_DB_USER = false;

// Whether to connect analytics session to user.uid
const ANALYTICS_IDENTIFY = true;

const authContext = createContext();
const userObjectContext = createContext();

// Hook that enables any component to subscribe to auth state
export const useAuth = () => useContext(authContext);
export const useUserObject = () => useContext(userObjectContext);

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => (props) => {
  // Get authenticated user
  const auth = useAuth();

  useEffect(() => {
    // Redirect if not signed in
    if (auth.user === false) {
      history.replace("/auth/signin");
    }
  }, [auth]);

  // Show loading indicator
  // We're either loading (user is null) or we're about to redirect (user is false)
  if (!auth.user) {
    return <PageLoader />;
  }

  // Render component now that we have user
  return <Component {...props} />;
};

const allProviders = [
  {
    id: "auth0",
    name: "password",
  },
  {
    id: "google-oauth2",
    name: "google",
  },
  {
    id: "facebook",
    name: "facebook",
  },
  {
    id: "twitter",
    name: "twitter",
  },
  {
    id: "github",
    name: "github",
  },
];

// Connect analytics session to current user.uid
function useIdentifyUser(user) {
  useEffect(() => {
    if (ANALYTICS_IDENTIFY && user) {
      analytics.identify(user.uid);
    }
  }, [user]);
}

// Handle response from authentication functions
async function handleAuth(
  user,
  isSigninRequest = false,
  isAuth0Request = false
) {
  try {
    const first = user.given_name || "";
    const last = user.family_name || "";
    const { email } = user;
    const password = user.password || "";
    const username =
      user.username ||
      (isAuth0Request
        ? `${user.nickname.replace(" ", "_").toLowerCase()}`
        : "");
    const uid = user.sub || "";
    const userObject = await CreateUser({
      uid,
      username,
      first_name: first,
      last_name: last,
      bio: "",
      email,
      password,
      signin_request: isSigninRequest,
      auth0_request: isAuth0Request,
    });
    // eslint-disable-next-line no-param-reassign
    user = userObject.user;
  } catch (error) {
    console.log(error);
  }
  return user;
}

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  const userDbQuery = GetUser(MERGE_DB_USER && user && user.sub);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) return user;

    // Data we want to include from auth user object
    const finalUser = {
      uid: user.sub,
      email: user.email,
      emailVerified: user.email_verified,
      name: user.name,
      picture: user.picture,
    };

    // Get the provider which is prepended to user.sub
    const providerId = user.sub.split("|")[0];
    // Include an array of user's auth provider, such as ["password"]
    // Components can read this to prompt user to re-auth with the correct provider
    // In the future this may contain multiple if Auth0 Account Linking is implemented.
    const providerName = allProviders.find((p) => p.id === providerId).name;
    finalUser.providers = [providerName];

    // If merging user data from database is enabled ...
    if (MERGE_DB_USER) {
      switch (userDbQuery.status) {
        case "idle":
          // Return null user until we have db data to merge
          return null;
        case "loading":
          return null;
        case "error":
          // Log query error to console
          console.error(userDbQuery.error);
          return null;
        case "success":
          // If user data doesn't exist we assume this means user just signed up and the createUser
          // function just hasn't completed. We return null to indicate a loading state.
          if (userDbQuery.data === null) return null;

          // Merge user data from database into finalUser object
          Object.assign(finalUser, userDbQuery.data);

          // Get values we need for setting up some custom fields below
          const { stripePriceId, stripeSubscriptionStatus } = userDbQuery.data;

          // Add planId field (such as "basic", "premium", etc) based on stripePriceId
          if (stripePriceId) {
            finalUser.planId = getFriendlyPlanId(stripePriceId);
          }

          // Add planIsActive field and set to true if subscription status is "active" or "trialing"
          finalUser.planIsActive = ["active", "trialing"].includes(
            stripeSubscriptionStatus
          );

        // no default
      }
    }

    return finalUser;
  }, [user, userDbQuery]);
}

// Provider hook that creates auth object and handles state
function useAuthProvider() {
  // Store auth user object
  const [user, setUser] = useState(null);

  // Format final user object and merge extra data from database
  const finalUser = usePrepareUser(user);

  // Connect analytics session to user
  useIdentifyUser(finalUser);

  const signup = async (email, password, username) => {
    const userObject = await handleAuth(
      { email, password, username },
      false,
      false
    );
    return userObject;
  };

  const signin = async (email, password) => {
    const userObject = await handleAuth({ email, password }, true);
    return userObject;
  };

  const signinWithProvider = async (name) => {
    // Get current domain
    const domain = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`;
    const providerId = allProviders.find((p) => p.name === name).id;

    let userObject = await auth0.extended.popupAuthorize({
      redirectUri: `${domain}/auth0-callback`,
      connection: providerId,
    });
    userObject = await handleAuth(userObject, false, true);
    return userObject;
  };

  const signout = () => auth0.extended.logout();

  const sendPasswordResetEmail = (email) =>
    auth0.extended.changePassword({
      email,
    });

  const confirmPasswordReset = (password, code) =>
    // This method is not needed with Auth0 but added in case your exported
    // Divjoy template makes a call to auth.confirmPasswordReset(). You can remove it.
    Promise.reject(
      new ResponseError(
        "not_needed",
        "Auth0 handles the password reset flow for you. You can remove this section or page."
      )
    );
  const updateEmail = (email) =>
    auth0.extended.updateEmail(email).then(() => {
      setUser({ ...user, email });
    });

  const updatePassword = (password) => auth0.extended.updatePassword(password);

  // Update auth user and persist to database (including any custom values in data)
  // Forms can call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const { email, name, picture } = data;

    // Update auth email
    if (email) {
      await auth0.extended.updateEmail(email);
    }

    // Update auth profile fields
    if (name || picture) {
      const fields = {};
      if (name) fields.name = name;
      if (picture) fields.picture = picture;
      await auth0.extended.updateProfile(fields);
    }

    // Persist all data to the database
    await updateUser(user.sub, data);

    // Update user in state
    const currentUser = await auth0.extended.getCurrentUser();
    setUser(currentUser);
  };

  useEffect(() => {
    // Subscribe to user on mount
    const unsubscribe = auth0.extended.onChange(async (userObject) => {
      if (userObject) {
        setUser(userObject);
      } else {
        setUser(false);
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  return {
    user: finalUser,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    updateProfile,
  };
}

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const UserObjectProvider = ({ children }) => {
  const auth = useAuth();
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const userObject = auth.user !== null ? GetUserByEmail(auth.user.email) : {};
  return (
    <userObjectContext.Provider value={userObject}>
      {children}
    </userObjectContext.Provider>
  );
};
