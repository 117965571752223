import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "../../util/router";

/**
 * Solid buttons that are used for navigation
 */
export const SolidNavButton = (props) => {
  const { buttonText, overrideColor, textColor, path, isLoading, size } = props;

  return (
    <Button
      variant="contained"
      size={size ?? "medium"}
      component={Link}
      to={path}
      color={textColor ?? "primary"}
      backgroundcolor={overrideColor ?? "default"}
      style={{
        margin: 2,
      }}
      disabled={isLoading ?? false}
    >
      {!isLoading ? buttonText : <CircularProgress size={20} />}
    </Button>
  );
};
