import { features } from "./features.constants";

const FeatureSection = () => (
  <div className="py-12 bg-white">
    <div className="max-w-xl mx-auto px-8 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 className="sr-only">A better way to send money.</h2>
      <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8">
        {features.map((feature) => (
          <div key={feature.name}>
            <dt>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-300 text-white">
                <feature.icon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                {feature.name}
              </p>
            </dt>
            <dd className="mt-2 text-base text-gray-500">
              {feature.description}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  </div>
);

export default FeatureSection;
