import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ProfileInfoSection from "../components/profile/ProfileInfoSection";
import ProfileDocSection from "../components/google_doc/ProfileDocSection";
import ProfileCollectionsSection from "../components/profile/ProfileCollectionsSection";
import Contributions from "../components/profile/Contributions";
import ProfileTabs from "../components/profile/ProfileTabs.js";

const ProfilePage = () => {
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <div style={{ paddingLeft: "50px" }}>
      <Grid container>
        <Grid item sm={2}>
          <ProfileInfoSection />
        </Grid>
        <Grid item sm={9}>
          <ProfileTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

          {currentTab === 0 && (
            <>
              <br />
              <div style={{ paddingBottom: 20 }}>
                <Typography variant="h5">Guides</Typography>{" "}
              </div>
              <ProfileDocSection maxShow={4} />
              <div style={{ paddingTop: 30 }}>
                <Contributions />
              </div>
              <div style={{ paddingTop: 30 }}>
                <Typography variant="h5">Collections</Typography>
                <div style={{ paddingTop: 20 }}>
                  <ProfileCollectionsSection maxShow={10} />
                </div>
              </div>
            </>
          )}

          {currentTab === 1 && (
            <div style={{ paddingTop: 30 }}>
              <ProfileDocSection maxShow={20} />
            </div>
          )}

          {currentTab === 2 && (
            <div style={{ paddingTop: 30 }}>
              <ProfileCollectionsSection maxShow={30} />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfilePage;
