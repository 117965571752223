import React from "react";
import Grid from "@material-ui/core/Grid";
import { OutlinedActionButton } from "../common/OutlinedActionButton";
import { SolidActionButton } from "../common/SolidActionButton";
import { RECOMMENDED_TAGS } from "./TagsConstants";

export default function TagsMultiSelectSection(props) {
  const { selectedTags, setSelectedTags } = props;

  function multiSelectTags(currentTag) {
    const lowerTagCase = currentTag.toLowerCase();
    let updatedSelectedTag = [...selectedTags];
    updatedSelectedTag = updatedSelectedTag.filter(
      (tag) => tag !== lowerTagCase
    );
    if (!selectedTags.includes(lowerTagCase)) {
      updatedSelectedTag.push(lowerTagCase);
    }
    setSelectedTags(updatedSelectedTag);
    
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Grid container justify="center">
        {RECOMMENDED_TAGS.map((tag) => (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {selectedTags.includes(tag) ? (
                <SolidActionButton
                  buttonText={tag}
                  onClick={() => multiSelectTags(tag)}
                />
              ) : (
                <OutlinedActionButton
                  buttonText={tag}
                  onClick={() => multiSelectTags(tag)}
                />
              )}
            </div>
          ))}
      </Grid>
    </div>
  );
}
