import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OutlinedNavButton } from "../common/OutlinedNavButton";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(3),
    "& > a": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

export default function AuthFooter(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.type === "signup" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Have an account already?
          <div style={{ marginLeft: 10 }}>
            <OutlinedNavButton
              buttonText={props.typeValues.linkTextSignin}
              path="/auth/signin"
              textColor="primary"
              overrideColor="default"
              size="small"
            />
          </div>
        </div>
      )}

      {props.type === "signin" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Don't have an account?
          <div style={{ marginLeft: 10 }}>
            <OutlinedNavButton
              buttonText={props.typeValues.linkTextSignup}
              path="/auth/signup"
              textColor="primary"
              overrideColor="default"
              size="small"
            />
          </div>
        </div>
      )}
    </div>
  );
}
