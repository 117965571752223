import styled from "styled-components";

export const Text = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.size ? props.size : null)};
  line-height: ${(props) => (props.size ? props.leading : null)};
  color: ${(props) => (props.color ? props.color : "#000000")};
  padding-bottom: ${(props) =>
    props.bottomPadding ? props.bottomPadding : null};
  padding-left: ${(props) => (props.leftPadding ? props.leftPadding : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
`;

export const AvatarImage = styled.img`
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  margin-right: ${(props) => (props.margin ? props.margin : null)};
`;

export const SignUpInButton = styled.button`
  font-size: 16px;
  font-weight: 500;
  background: #000000;
  border-radius: 3px;
  padding: 8px 60px;
  color: #fff;
  text-align: center;
  width: 285px;
  margin-top: 10px;
  border: 1px solid #000000;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 11px;
  margin-top: 20px;

  &:hover {
    background: #262424;
    border: 1px solid #000000;
  }
`;

export const GoogleButton = styled.button`
  background-color: white !important;
  color: black;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  padding: 8px 50px;
  width: 310px;
  border: 2px solid black;
  border-radius: 3px;
  display: flex;
  align-self: center;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8 !important;
  }
`;

export const Wrapper = styled.div`
  max-width: 320px;
  margin: auto;
  padding: 18px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
