import React from "react";
import { Grid } from "@material-ui/core";
import ProfileUserCollectionsSections from "../components/collection/ProfileUserCollectionsSections";
import Sidebar from "../components/sidebar/Sidebar.tsx";
import { requireAuth } from "../util/auth";

const CollectionsPage = () => (
  // TODO: 1. SHOW COLLECTIONS CREATED BY USER
  // TODO: 2. SHOW COLLECTIONS SAVED BY USER

  <Grid container direction="row" spacing={2}>
    <Grid item>
      <div className="sidebarDashboard">
        <Sidebar page="dashboard" />
      </div>
    </Grid>
    <Grid item>
      <div display="flex" flexDirection="column">
        <ProfileUserCollectionsSections />
      </div>
    </Grid>
  </Grid>
);

export default requireAuth(CollectionsPage);
