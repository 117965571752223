import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { requireAuth } from '../../util/auth';
import { SignUpInButton } from '../auth/Global';
import GoogleDocPage from '../google_doc/GoogleDocPage';
import GoogleSheetPage from '../google_sheet/GoogleSheetPage';

const NewNotebook = () => {
  const [googleDocsURL, setGoogleDocsURL] = useState("");
  const [submit, setSubmit] = useState(false);

  // Used to import either google doc or sheet
  const docType = googleDocsURL.split("/")[3];

  const GoogleDocOrSheetURLInput = (
    <div
      style={{
        maxWidth: 400,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <TextField
        variant="outlined"
        label="Google Doc URL"
        id="google-doc-url"
        placeholder="https://docs.google.com/document/d/..."
        size="medium"
        helperText="Please enter the google docs link, shared with at least public view access."
        fullWidth
        onChange={(url) => setGoogleDocsURL(url.target.value)}
        value={googleDocsURL}
      />

      <SignUpInButton onClick={() => setSubmit(true)}>
        <span>Import Google Doc</span>
      </SignUpInButton>

      <Box mt={3}>
        <Typography paragraph>
          Don't have a link? {` `}
          <Link href="https://doc.new" underline="always" target="_blank">
            Create a google doc
          </Link>
          {` `} then share the link with permission access granted.
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography paragraph variant="subtitle2" color="textSecondary">
          Keep in mind that if the link has Editor permissions, it is editable
          for everyone. We recommend sharing links with Viewer or Commenter
          permissions for easier moderation.
        </Typography>
      </Box>
    </div>
  );

  return (
    <div
      style={{
        marginTop: 30,
        marginBottom: 30,
      }}
    >
      {submit ? (
        <>
          {docType === "document" && (
            <GoogleDocPage newImport googleDocsURL={googleDocsURL} />
          )}
          {docType === "spreadsheets" && (
            <GoogleSheetPage newImport googleDocsURL={googleDocsURL} />
          )}
        </>
      ) : (
        GoogleDocOrSheetURLInput
      )}
    </div>
  );
};

export default requireAuth(NewNotebook);
