import React from "react";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { GetUser } from "../../db/user";

export default function ProfileInfoSection(props) {
  const { id } = useParams();
  const user = GetUser(id);

  return (
    <div>
      {user && user.data && user.data.author ? (
        <div style={{ marginTop: 40 }}>
          <Typography gutterBottom variant="h6" style={{ marginBottom: 30 }}>
            {`${user.data.author.first_name} ${user.data.author.last_name}`}
          </Typography>
          {/* <Typography
            variant="body1"
            color="textSecondary"
            gutterBottom={true}
            style={{ marginBottom: 15 }}
          >
            {"@" + user.data.author.username}
          </Typography>
          <br /> */}
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginBottom: 30, maxWidth: 250, marginRight: 45 }}
          >
            {user.data.author.bio}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}
