import { useQuery, queryCache, QueryResult } from "react-query";
import { apiRequest } from "../util/util";

type User = {
  uid: string;
  username: string;
  email: string;
  creation_date: Date;
  first_name: string;
  last_name: string;
  bio?: string;
  password?: string;
};

export function CreateUser(data: User): Promise<User> {
  return apiRequest<User>("user-create", "POST", data);
}

// This is called automatically by auth.js and merged into auth.user
export function GetUser(uid: string): QueryResult<User> {
  // Unique cache key for this query
  const cacheKey = ["user", { uid }];
  const query = () => apiRequest<User>(`user-get/${uid}`);
  // Fetch with react-query (only if we have a uid)
  // Docs: https://react-query.tanstack.com/docs/guides/queries
  return useQuery(cacheKey, query, { enabled: !!uid });
}

export function GetUserByEmail(email: string): QueryResult<User> {
  const cacheKey = ["user-by-email", { email }];
  const query = () => apiRequest<User>(`user-get-by-email?email=${email}`);
  return useQuery(cacheKey, query, { enabled: !!email });
}

export function updateUser(uid: string, data: User): Promise<User> {
  return apiRequest<User>(`user-update/${uid}`, "PATCH", data).then((user) => {
    const cacheKey = ["user", { uid }];
    // Update user in cache (causing components to re-render with new data)
    queryCache.setQueryData(cacheKey, user);
    return user;
  });
}

// Retrieves dates for users activity: creating a block, creating a notebook
// TODO: add edit a block dates
export function GetUserContributions(uid: string): QueryResult<User> {
  const cacheKey = ["user-contributions", { uid }];
  const query = () => apiRequest<User>(`user-get-contributions/${uid}`);
  return useQuery(cacheKey, query, { enabled: !!uid });
}
