import {
    AnnotationIcon,
    BookmarkIcon,
    FolderAddIcon,
    UserGroupIcon,
  } from '@heroicons/react/outline'


export type FeaturesProps = {
    name: string;
    description: string;
    icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  };

export const features: (FeaturesProps)[] = [
  {
    name: 'Collect Guides',
    description: 'Easily save an access useful guides with personalized collection function.',
    icon: BookmarkIcon,
  },
  {
    name: 'Curate Collections',
    description: `Save and share your collections so you don't lose important information.`,
    icon: FolderAddIcon,
  },
  {
    name: 'Create or Contribute',
    description: `Start new guides and invite others to contribute. Or make a small contribution in an existing guide.`,
    icon: AnnotationIcon,
  },
  {
    name: 'Community Building',
    description: 'Connect with contributors and readers interested in the same topics as you!',
    icon: UserGroupIcon,
  },
]