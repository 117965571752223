import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Solid buttons that are used for functionality defined by onClick handlers
 */
export const SolidActionButton = (props) => {
  const { buttonText, overrideColor, textColor, isLoading, onClick, size } =
    props;

  return (
    <Button
      variant="contained"
      text
      size={size ?? "medium"}
      color={textColor ?? "primary"}
      backgroundColor={overrideColor ?? "default"}
      style={{
        margin: 2,
      }}
      disabled={isLoading ?? false}
      onClick={onClick}
    >
      {!isLoading ? buttonText : <CircularProgress size={20} />}
    </Button>
  );
};
