import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CreateGoogleDoc } from "../../../db/google_doc";
import { SignUpInButton } from "../../auth/Global";
import TagsMultiSelectSection from "../../tags/TagsMultiSelectSection.js";
import { useUserObject } from "../../../util/auth";

const useStyles = makeStyles((theme) => ({
  titleInput: {
    maxWidth: 320,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  descriptionInput: {
    minHeight: "300px",
  },
}));

export default function GoogleDocSettings(props) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [notebookTitle, setNotebookTitle] = useState("-$%^");
  const [notebookDescription, setNotebookDescription] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const userObject = useUserObject();
  let viewerUserID;
  if (userObject !== undefined && userObject.status === "success") {
    viewerUserID = userObject.data.author.id;
  }

  const { url, data } = props;
  function handlePublishDoc() {
    CreateGoogleDoc({
      fileID: url.split("/")[5],
      author: viewerUserID,
      title: notebookTitle !== "-$%^" ? notebookTitle : data.title,
      description: notebookDescription,
      html_doc_content: data.google_doc,
      tags: selectedTags,
      url,
    })
      .then((res) => {
        history.replace(`/notebook/${res.google_doc.fileid}`);
      })
      .catch((err) => {
        console.log(err);
      });
    props.setEditMode(false);
  }

  return (
    <>
      <div className={classes.titleInput}>
        <TextField
          variant="outlined"
          label="Notebook Title"
          id="notebook-title"
          size="medium"
          fullWidth
          onChange={(title) => setNotebookTitle(title.target.value)}
          value={notebookTitle !== "-$%^" ? notebookTitle : data.title}
        />
      </div>
      <div
        style={{
          maxWidth: 320,
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <TextField
          multiline
          variant="outlined"
          label="Notebook Description (optional)"
          id="notebook-description"
          rows={4}
          fullWidth
          onChange={(description) =>
            setNotebookDescription(description.target.value)
          }
          value={notebookDescription}
        />
      </div>
      <div
        style={{
          maxWidth: 900,
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <TagsMultiSelectSection
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </div>
      <div
        style={{
          maxWidth: 320,
          marginTop: 40,
          marginBottom: 20,
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <SignUpInButton onClick={() => handlePublishDoc()}>
          <span>Publish Notebook</span>
        </SignUpInButton>
      </div>
    </>
  );
}
