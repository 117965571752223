import React from "react";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { BookOpenIcon } from "@heroicons/react/solid";
import { GetGoogleDocByAuthor } from "../../db/google_doc";
import EmptySectionPlaceholder from "../profile/EmptySectionPlaceholder";
import GoogleDocPreviewCard from "./components/GoogleDocPreviewCard";

interface ProfileDocSectionProps {
  maxShow: number;
}

export default function ProfileDocSection(props: ProfileDocSectionProps) {
  const { id } = useParams<{ id: string }>();
  const { maxShow } = props;
  const result = GetGoogleDocByAuthor(id);

  return (
    <Grid container spacing={3}>
      {result && result.status === "loading" && <div>Loading Data...</div>}
      {result !== undefined &&
      result.status === "success" &&
      result.data &&
      result.data.google_docs.length > 0 ? (
        result.data.google_docs.map((googleDoc, index) =>
          index < maxShow ? (
            <GoogleDocPreviewCard key={index} notebook={googleDoc} />
          ) : null
        )
      ) : (
        <EmptySectionPlaceholder
          text="No guides added yet"
          Icon={BookOpenIcon}
        />
      )}
    </Grid>
  );
}
