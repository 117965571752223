import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import { OutlinedActionButton } from "../common/OutlinedActionButton";
import { GetGoogleSheetByFileID } from "../../db/google_sheet";
import GoogleSheetContent from "./GoogleSheetContent";

const useStyles = makeStyles(() => ({
  paperblocks: {
    minHeight: "300px",
  },
}));

export default function GoogleSheetPage(props) {
  let { id } = useParams();
  id = props.newImport ? props.googleDocsURL.split("/")[5] : id.toString();
  const [editMode, setEditMode] = useState(props.newImport);
  const classes = useStyles();
  const { data, status, error } = props.newImport
    ? GetGoogleSheetByFileID(id)
    : GetGoogleSheetByFileID(id);
  const urlStart = "https://docs.google.com/spreadsheets/d/";

  const GoogleSheetViewMode = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        <Typography variant="h4">{data.google_sheet.title}</Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {data.google_sheet.description}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
          padding: 20,
        }}
      >
        <OutlinedActionButton
          buttonText="View in Google Sheet"
          onClick={() => window.open(urlStart + id)}
        />
      </div>
    </>
  );

  return (
    <>
      {error && (
        <Box mb={3}>
          <Alert severity="error">
            {props.newImport
              ? "Try again with a different FileID. Please make sure the doc is public and that you have given correct access (View, Comment, or Edit)."
              : "Sorry, the file you have requested is not available."}
          </Alert>
        </Box>
      )}

      {status === "loading" && (
        <Paper className={classes.paperblocks}>
          <Box py={5} px={3} align="center">
            {status === "loading" && <CircularProgress size={32} />}
          </Box>
        </Paper>
      )}

      {status === "success" && data && (
        <>
          {editMode && (
            // <GoogleDocSettings data={data} url={props.googleDocsURL} />
            <GoogleSheetViewMode />
          )}

          <GoogleSheetContent content={data.google_sheet} />
        </>
      )}
    </>
  );
}
